@use './styles/variables' as v;

/* You can add global styles to this file, and also import other style files */

body {
    font-family: v.$primary-font;
    color: v.$primary-font-color;
    margin: 0;
    font-weight: 400;
    height: 100%;
}

html {
    height: 100%;
}
